<template>
	<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
		<template slot="action" slot-scope="{ columnData }">
			<a @click="Detail(columnData.id)">{{ $t('page.checkDetail') }}</a>
		</template>
	</search-list-table>
</template>
<script>
import moment from 'moment'
import { apiGetDisburseApply } from '@/api/loan'
export default {
	data() {
		return {}
	},
	computed: {
		formSearchList() {
			return [
				// {
				// 	label: 'table.applyInfo.cloums.caseId',
				// 	type: 'input',
				// 	validateField: ['caseId']
				// },
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.cloums.IdNumber',
					type: 'input',
					validateField: ['identityCard']
				},
				{
					label: 'table.applyInfo.cloums.status',
					type: 'select',
					validateField: ['statuses'],
					selectOption: [
						'apply_info_input',
						'apply_info_check',
						'apply_info_check_fail',
						'disburse_info_input',
						'disburse_info_check',
						'disburse_info_check_fail',
						'disburse_waiting',
						'disburse_success',
						'file'
					],
					prefix: 'table.applyInfo.recordCloums.nodeStatus'
				},
				{
					label: 'table.applyInfo.cloums.createTime',
					type: 'range-picker',
					validateField: ['createTime', { initialValue: [moment(new Date().setMonth(new Date().getMonth() - 1)), moment()] }]
				}
			]
		},
		columns() {
			return [
				{
					// 案件ID
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					width: 80,
					align: 'center'
				},
				{
					// 案件ID
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'id',
					align: 'center',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				{
					// 案件状态
					title: this.$t('table.applyInfo.cloums.caseStatus'),
					dataIndex: 'statusCode',
					align: 'center',
					customRender: (v, o) => {
						return this.$t(`table.applyInfo.recordCloums.nodeStatus.${v}`)
					}
				},
				{
					// 身份ID
					title: this.$t('table.applyInfo.cloums.IdNumber'),
					dataIndex: 'identityCard',
					align: 'center'
				},
				{
					// 用户名
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'firstName',
					align: 'center',
					width: 150,
					customRender: (v, o) => (v ? v : '') + ' ' + (o.middleName ? o.middleName : '') + ' ' + (o.lastName ? o.lastName : '')
				},
				{
					// 车牌号
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					dataIndex: 'registrationNumbers',
					align: 'center',
					customRender: (v) => (v ? v.join('/') : '-')
				},
				{
					// 申请金额
					title: this.$t('table.applyInfo.cloums.loanAmountRequested'),
					dataIndex: 'loanApplicationAmount',
					align: 'center',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					// 申请期限
					title: this.$t('table.applyInfo.cloums.loanTerm'),
					dataIndex: 'installmentsNumbers',
					align: 'center'
				},
				{
					// 创建时间
					title: this.$t('table.applyInfo.cloums.createTime'),
					dataIndex: 'createTime',
					align: 'center'
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			]
		}
	},
	methods: {
		initDataSource(initParams) {
			return apiGetDisburseApply(initParams)
		},
		moment,
		Detail(id) {
			this.$router.push({
				path: 'applydetail',
				query: {
					id
				}
			})
		}
	}
}
</script>
<style scoped lang="less"></style>
